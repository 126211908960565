import React, { useEffect, useState } from "react";
import {
  FormattedMessage,
  FormattedPlural,
  Link,
  useIntl,
} from "gatsby-plugin-intl";
import * as JsSearch from "js-search";

import Layout from "../components/layout";
import HorizontalCard from "../components/horizontal-card";
import { graphql } from "gatsby";
import SearchBar from "../components/search-bar";

const Resources = (props: {
  data: { allMarkdownRemark: { nodes: { frontmatter: any }[] } };
  pageContext: { language: string | number };
}) => {
  const intl = useIntl();
  const data = props.data.allMarkdownRemark.nodes[0].frontmatter;
  const { title, resources } = data[props.pageContext.language];

  const [resourcesList, setResources] = useState(resources);
  const [search, setSearch] = useState<JsSearch.Search>();
  const [searchQuery, setSearchQuery] = useState("");
  const [queryResults, setQueryResults] = useState(resourcesList);
  const [searchResults, setSearchResults] = useState<Object[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    rebuildIndex();
    setQueryResults(searchQuery === "" ? resourcesList : searchResults);
  }, [searchResults]);

  const rebuildIndex = () => {
    const dataToSearch = new JsSearch.Search("url");

    /**
     * defines an indexing strategy for the data
     * more about it in here https://github.com/bvaughn/js-search#configuring-the-index-strategy
     */
    dataToSearch.indexStrategy = new JsSearch.PrefixIndexStrategy();
    /**
     * defines the sanitizer for the search
     * to prevent some of the words from being excluded
     *
     */
    dataToSearch.sanitizer = new JsSearch.LowerCaseSanitizer();
    /**
     * defines the search index
     * read more in here https://github.com/bvaughn/js-search#configuring-the-search-index
     */
    dataToSearch.searchIndex = new JsSearch.TfIdfSearchIndex("title");
    dataToSearch.addIndex("url"); // sets the index attribute for the data
    dataToSearch.addIndex("linkText"); // sets the index attribute for the data
    dataToSearch.addIndex("description"); // sets the index attribute for the data
    dataToSearch.addDocuments(resources); // adds the data to be searched
    setSearch(dataToSearch);
    setIsLoading(false);
  };

  /**
   * handles the input change and perform a search with js-search
   * in which the results will be added to the state
   */
  const searchData = (e: { target: any }) => {
    // const { search } = this.state
    if (search?.search) {
      const queryResult = search.search(e.target.value as string);
      setSearchQuery(e.target.value);
      setSearchResults(queryResult);
    }
  };

  const resetForm = () => {
    setSearchQuery("");
    setSearchResults([]);
  };

  const handleSearchReset = () => {
    resetForm();
  };

  const handleOnSearchChange = (event: React.ChangeEvent<HTMLElement>) => {
    searchData(event);
  };

  return (
    <Layout>
      <h1 className="block-title">{title}</h1>
      <section className="mt-4">
        <h2 className="anchor-title">
          {" "}
          <FormattedMessage id="case_studies" defaultMessage="Case Studies" />
        </h2>
      </section>
      <Link className="featured-link" to="/case-studies">
        <HorizontalCard
          url="/case-studies"
          linkText={
            <FormattedMessage id="case_studies" defaultMessage="Case Studies" />
          }
          description={
            <FormattedMessage
              id="case_studies_description"
              defaultMessage="Find out about the projects that have been approved. View
                      references to assist you in answering your own project questions."
            />
          }
          imageUrl="/img/staticImages/case-studies.webp"
          imageAltText=""
        ></HorizontalCard>
      </Link>
      <section className="mt-5">
        <h2 className="anchor-title">
          <FormattedMessage
            id="further_resources"
            defaultMessage="Further Resources"
          />
        </h2>
        <SearchBar
          onReset={handleSearchReset}
          onChange={(e) => handleOnSearchChange(e)}
          searchQuery={searchQuery}
        ></SearchBar>
        {!!searchQuery.length && (
          <h3 className="search-results-title">
            <FormattedMessage
              id="resources.results_title"
              defaultMessage=" Showing {itemCount ,plural,
                =0 {no results}
                one {1 result}
                other {{itemCount} results}} for {query}"
              values={{
                itemCount: queryResults.length,
                query: searchQuery,
              }}
            />
          </h3>
        )}

        <ul className="resources-list ps-0">
          {queryResults.map(
            (node: {
              linkText: string;
              description: string;
              url: string;
              image: string;
              alt: string;
            }) => (
              <li key={node.linkText} className="resource-item">
                {node.url.startsWith("http") ? (
                  <a className="featured-link" href={node.url}>
                    <HorizontalCard
                      url={node.url}
                      linkText={node.linkText}
                      description={node.description}
                      imageUrl={node.image}
                      imageAltText={node.alt}
                    ></HorizontalCard>
                  </a>
                ) : (
                  <Link className="featured-link" to={node.url}>
                    <HorizontalCard
                      url={node.url}
                      linkText={node.linkText}
                      description={node.description}
                      imageUrl={node.image}
                      imageAltText={node.alt}
                    ></HorizontalCard>
                  </Link>
                )}
              </li>
            )
          )}
          <li></li>
        </ul>
      </section>
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(resource-page)/" } }
    ) {
      nodes {
        frontmatter {
          title
          en {
            title
            resources {
              url
              linkText
              description
              image
              alt
            }
          }
          cy {
            title
            resources {
              url
              linkText
              description
              image
              alt
            }
          }
        }
      }
    }
  }
`;

export default Resources;
